<template>
  <q-layout view="lHh Lpr lff">
    <q-header :elevated="$route.name != 'index'" :reveal="$route.name != 'index'" class="fixed-top">
      <q-banner v-if="false" dense class="bg-red-8 text-white text-center q-py-xs">
        <strong>
          Happy Presidents' Day! Use the code
          <q-chip dense square class="bg-white text-primary">jstaffingPres25</q-chip> for a 25% discount. <small>Expires 02/21/20</small></strong
        >
      </q-banner>
      <q-toolbar class="">
        <q-btn flat dense round aria-label="Menu" @click="toggleDrawer()">
          <q-icon name="menu" />
        </q-btn>

        <q-toolbar-title>
          <router-link :to="{ name: isLoggedIn ? 'home' : 'index' }" class="text-white">
            Degree Jobs
          </router-link>
        </q-toolbar-title>

        <q-space></q-space>

        <q-btn v-show="!isLoggedIn" color="positive" to="/auth/login" no-caps>
          Log in
        </q-btn>

        <q-btn v-show="!isLoggedIn" color="blue" to="/auth/register" class="q-mx-sm" no-caps>
          Register
        </q-btn>

        <q-btn v-show="isLoggedIn" color="red" to="/account/jobs/prep/start" icon-right="keyboard_arrow_right" class="q-mx-sm" no-caps dense>
          &nbsp;Post a Job&nbsp;
        </q-btn>

        <q-btn v-show="isLoggedIn" flat round to="/account/home">
          <q-icon name="person" />
        </q-btn>

        <q-btn v-show="isLoggedIn && hasRole('admin')" flat round to="/admin/home">
          <q-icon size="1.3em" name="far fa-shield-alt" />
        </q-btn>

        <q-btn flat round alt="Contact Support" @click="showContactDialog()">
          <q-icon alt="Contact Support" size="1.4em" name="fas fa-headset" />
        </q-btn>

        <q-btn v-show="isLoggedIn" flat round @click="logout">
          <q-icon size="1.1em" name="fas fa-lock-open" />
        </q-btn>

        <div></div>
      </q-toolbar>
    </q-header>

    <q-drawer v-model="leftDrawerOpen" :mini="miniDrawer" :width="240" bordered content-class="bg-grey-2">
      <q-scroll-area class="fit">
        <q-card v-show="!miniDrawer" flat style="min-height: 60px;" class="bg-grey-2 q-py-md">
          <q-card-section v-if="user" class="text-body1 text-weight-bold">
            {{ user.first_name || 'Guest' }}
            {{ user.last_name || '' }}
          </q-card-section>
          <q-card-section v-if="!user" class="text-body1 text-weight-bold">
            Guest
          </q-card-section>
        </q-card>

        <q-card v-show="!miniDrawer" v-if="hasRole('admin')" flat style="min-height: 20px;" class="bg-grey-2 q-py-none">
          <q-card-section class="row text-body1 text-weight-bold justify-between q-py-none">
            <div>
              Admin
            </div>
            <q-btn dense flat size="sm" icon="add" :to="{ name: 'accountJobsPrepStart' }" />
            <q-toggle dense :value="hasRole('debug')" color="negative" icon="adb" @input="setDebug($event)" />
          </q-card-section>
        </q-card>

        <q-card v-if="false" v-show="!miniDrawer" flat style="min-height: 100px;" class="bg-grey-2 q-py-md">
          <q-input v-model="text" dense bottom-slots class="q-mx-md">
            <template v-slot:append>
              <q-icon v-if="text === ''" name="search" />
              <q-icon v-else name="clear" class="cursor-pointer" @click="text = ''" />
            </template>
            <template v-slot:counter>
              <router-link to="/search" class="text-primary">Advanced Search</router-link>
            </template>
          </q-input>
        </q-card>

        <q-list>
          <q-expansion-item
            dense-toggle
            expand-separator
            default-opened
            icon="folder"
            header-class="text-weight-medium"
            label="Jobs"
            @click="miniDrawer = false"
          >
            <template v-slot:header>
              <q-item-section avatar>
                <q-icon name="folder"></q-icon>
              </q-item-section>

              <q-item-section>
                Jobs
              </q-item-section>
            </template>

            <q-list dense>
              <q-item v-ripple clickable to="/jobs/list/1">
                <q-item-section class="q-pl-lg">
                  All Jobs
                </q-item-section>
              </q-item>

              <q-item v-if="false" v-ripple clickable to="/jobs/search/1">
                <q-item-section class="q-pl-lg">
                  Search Results
                </q-item-section>
              </q-item>

              <q-item v-ripple clickable to="/jobs/state">
                <q-item-section class="q-pl-lg">
                  Jobs by State
                </q-item-section>
              </q-item>

              <q-item v-ripple clickable to="/jobs/skill">
                <q-item-section class="q-pl-lg">
                  Jobs by Skill
                </q-item-section>
              </q-item>

              <q-item v-ripple clickable to="/jobs/contract_type">
                <q-item-section class="q-pl-lg">
                  Jobs by Contract Type
                </q-item-section>
              </q-item>

              <q-item v-ripple clickable to="/jobs/orgtype">
                <q-item-section class="q-pl-lg">
                  Jobs by Org Type
                </q-item-section>
              </q-item>

              <q-item v-ripple clickable to="/jobs/degree">
                <q-item-section class="q-pl-lg">
                  Jobs by Degree
                </q-item-section>
              </q-item>

              <q-item v-ripple clickable to="/jobs/country">
                <q-item-section class="q-pl-lg">
                  Jobs by Country
                </q-item-section>
              </q-item>
            </q-list>
          </q-expansion-item>

          <q-item padded>
            <q-btn
              v-show="!miniDrawer"
              :to="{ name: 'accountJobsPrepStart' }"
              dense
              color="positive"
              align="around"
              class="full-width"
              icon-right="keyboard_arrow_right"
            >
              Post a Job
            </q-btn>
            <q-btn v-show="miniDrawer" :to="{ name: 'accountJobsPrepStart' }" dense color="positive">
              <q-icon name="far fa-plus"></q-icon>
            </q-btn>
          </q-item>

          <q-expansion-item
            v-if="hasRole('debug')"
            dense-toggle
            expand-separator
            icon="fas fa-address-card"
            default-opened
            header-class="text-weight-medium"
            label="Canidiates"
            @click="miniDrawer = false"
          >
            <template v-slot:header>
              <q-item-section avatar>
                <q-icon name="fas fa-address-card"></q-icon>
              </q-item-section>

              <q-item-section>
                Candidates
              </q-item-section>

              <q-item-section side>
                <q-badge color="red" label="New" />
              </q-item-section>
            </template>

            <q-list dense>
              <q-item v-ripple clickable to="/candidates/list/1">
                <q-item-section class="q-pl-lg">
                  All Canidiates
                </q-item-section>
              </q-item>

              <q-item v-ripple clickable to="/candidates/state">
                <q-item-section class="q-pl-lg">
                  Candidates by State
                </q-item-section>
              </q-item>

              <q-item v-ripple clickable to="/candidates/me">
                <q-item-section class="q-pl-lg">
                  My Profile
                </q-item-section>
              </q-item>
            </q-list>
          </q-expansion-item>

          <q-expansion-item
            v-show="isLoggedIn"
            dense-toggle
            expand-separator
            icon="house"
            default-opened
            header-class="text-weight-medium"
            label="Organizations"
            @click="miniDrawer = false"
          >
            <q-list dense>
              <q-item v-ripple clickable to="/organizations/list/1">
                <q-item-section class="q-pl-lg">
                  All Organizations
                </q-item-section>
              </q-item>

              <q-item v-ripple clickable to="/organizations/state">
                <q-item-section class="q-pl-lg">
                  Orgs by State
                </q-item-section>
              </q-item>

              <q-item v-ripple clickable to="/organizations/add">
                <q-item-section class="q-pl-lg">
                  Add Organization
                </q-item-section>
              </q-item>
            </q-list>
          </q-expansion-item>

          <q-expansion-item
            v-show="isLoggedIn"
            dense-toggle
            expand-separator
            default-opened
            icon="person"
            header-class="text-weight-medium"
            label="Account"
            @click="miniDrawer = false"
          >
            <q-list dense>
              <q-item v-ripple clickable to="/account/home">
                <q-item-section class="q-pl-lg">
                  My Account
                </q-item-section>
              </q-item>

              <q-item v-ripple clickable to="/account/jobs/list/current/1">
                <q-item-section class="q-pl-lg">
                  <q-item-label>
                    My Jobs
                  </q-item-label>
                </q-item-section>
              </q-item>

              <q-item v-ripple clickable to="/account/orders/list/1">
                <q-item-section class="q-pl-lg">
                  Orders
                </q-item-section>
              </q-item>

              <q-item v-ripple clickable to="/account/bookmarks/list/1">
                <q-item-section class="q-pl-lg">
                  Bookmarks
                </q-item-section>
              </q-item>

              <q-item v-ripple clickable to="/account/viewhistory/list/1">
                <q-item-section class="q-pl-lg">
                  View History
                </q-item-section>
              </q-item>

              <q-item v-ripple clickable to="/account/apphistory/list/1">
                <q-item-section class="q-pl-lg">
                  Application History
                </q-item-section>
              </q-item>
            </q-list>
          </q-expansion-item>

          <q-expansion-item dense-toggle expand-separator icon="info" header-class="text-weight-medium" label="Help" @click="miniDrawer = false">
            <q-list dense>
              <q-item v-ripple clickable to="/about">
                <q-item-section class="q-pl-lg">
                  About
                </q-item-section>
              </q-item>

              <q-item v-ripple clickable to="/faq">
                <q-item-section class="q-pl-lg">
                  FAQ
                </q-item-section>
              </q-item>

              <q-item v-ripple clickable to="/terms">
                <q-item-section class="q-pl-lg">
                  Terms
                </q-item-section>
              </q-item>

              <q-item v-ripple clickable to="/privacy">
                <q-item-section class="q-pl-lg">
                  Privacy Policy
                </q-item-section>
              </q-item>

              <q-item v-ripple clickable to="/refund">
                <q-item-section class="q-pl-lg">
                  Refund Policy
                </q-item-section>
              </q-item>

              <q-item v-ripple clickable @click="showContactDialog()">
                <q-item-section class="q-pl-lg">
                  Contact Us
                </q-item-section>
              </q-item>
            </q-list>
          </q-expansion-item>
        </q-list>
      </q-scroll-area>
    </q-drawer>

    <q-page-container>
      <router-view />
    </q-page-container>

    <q-footer elevated bordered class="bg-grey-10 text-white">
      <div class="q-py-xl row">
        <div class="col-12 text-center text-subtitle2">
          Copyright &copy; {{ new Date().getYear() + 1900 }}
          <router-link to="/" class="text-white">{{brand}}</router-link>
          {{ version }}
        </div>
      </div>
      <div v-if="false" class="q-py-md row">
        <div class="col-3 q-px-xl q-py-md">
          <div class="text-h6"></div>
          <br />
          <a class="text-white" href="mailto:support@jstaffing.com">support@jstaffing.com</a>
        </div>

        <div class="col-3 q-pa-md">
          <div class="text-h6">Company Info</div>
          <ul class="q-mt-none">
            <li>
              <router-link :to="{ name: 'about' }" class="text-white">About</router-link>
            </li>

            <li>
              <router-link :to="{ name: 'pricing' }" class="text-white">Posting &amp; Pricing</router-link>
            </li>

            <li>
              <router-link :to="{ name: 'refund' }" class="text-white">Refunds</router-link>
            </li>

            <li>
              <router-link :to="{ name: 'terms' }" class="text-white">Terms</router-link>
            </li>

            <li>
              <router-link :to="{ name: 'privacy' }" class="text-white">Privacy Policy</router-link>
            </li>
          </ul>
        </div>
      </div>
    </q-footer>

    <q-dialog v-model="contactDialog" :auto-close="false" >
      <q-card style="width: 700px; max-width: 80vw;" class="bg-grey-2">
          <q-form ref="contactForm" @submit="submitContactForm">
        <q-card-section class="bg-orange-8 text-white">
          <div class="text-h6"><q-icon name="warning"></q-icon> Provide Feedback or Report an Issue
            <q-btn v-close-popup  flat icon='close' style="float: right;" />
          </div>
        </q-card-section>
          <q-card-section class="bg-grey-4 text-grey-9 q-py-none text-center ">
          </q-card-section>

        <q-card-section class="bg-grey-2">

          <div class="q-gutter-sm q-mt-lg">
            <div class="row">
                <div class="col-12 q-px-sm">
                    <q-select
                      v-model="contact.feedback_type"
                      :options="feedbackTypes"
                      dense
                      label="Contact Type"
                      emit-value
                      :display-value="feedbackTypes.filter( x => x.value == contact.feedback_type)[0].label"
                      :rules="[
                          val => !!val || 'Field is required',
                          val => getServerError('feedback_type'),
                        ]"
                    />
                </div>
              </div>

            <div class="row">
                <div class="col-12 q-px-sm">
                    <q-input
                      v-model="contact.email"
                      label="From Email"
                      dense
                      :rules="[
                        val => getServerError('email'),
                      ]"
                      @blur="clearServerError('email')"></q-input>
                </div>
            </div>

            <div class="row">
                <div class="col-12 q-px-sm">
                  <q-toggle v-model="contact.response">Please send me a response</q-toggle>
                </div>
            </div>


            <div class=" row">
                <div class="col-12 q-px-sm">
                  <q-input
                    v-model="contact.message"
                    label="Message"
                    type="textarea"
                    dense
                    min-height="5rem"
                    :rules="[
                      val => !!val || 'Field is required',
                      val => getServerError('message'),
                    ]"
                    @blur="clearServerError('message')"></q-input>
                </div>
            </div>

            <div class="row">
                <div class="col-12 q-pa-md bg-grey-4">
                  <div>
                  We respond to all requests, however, if your question is already answered in the <router-link :to="{ name: 'faq'} ">Frequently Asked Questions</router-link>, your question may be answered automatically asking you to kindly to go there for your answer.
                  </div>
                  <q-toggle v-model="contact.checked_faq">I have checked the <router-link :to="{ name: 'faq'} ">FAQ</router-link></q-toggle>
                </div>
            </div>

            <div class="row">
              <div class="col-12 q-px-sm">
                <show-data :data="contact"></show-data>
              </div>
            </div>

          </div>

        </q-card-section>

        <q-card-actions align="around" class="bg-grey-4">
          <q-btn v-close-popup type="button" flat label="Cancel" color="red" class="text-black" />
          <q-btn type="submit" color="primary" size="md" no-caps :disabled="!contact.checked_faq" :loading="sendingMessage">Send Message</q-btn>
        </q-card-actions>
      </q-form>
      </q-card>
    </q-dialog>
  </q-layout>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { AuthActionTypes } from 'src/modules/auth/store/action-types';
import { serverErrorMixin } from 'src/mixins/server_error.mixin';
import ApiService from 'src/services/api.service';
import { Notify } from 'quasar';

export default {
  name: 'DJLayout',
  mixins: [serverErrorMixin],
  data() {
    return {
      report: {
        message: null,
        url: null,
      },
      leftDrawerOpen: this.$q.platform.is.desktop && this.$route.name != 'index',
      miniDrawer: false,
      contactDialog: false,

      sendingMessage: false,
      contact: {
        email: null,
        message: null,
        feedback_type: 'help',
        checked_faq: false,
        response: false,
        page: null,
      },

      feedbackTypes: [
        {
          label: 'Help / Support',
          value: 'help',
        },

        {
          label: 'General Feedback',
          value: 'feedback',
        },

        {
          label: 'Feature Request',
          value: 'featurerequest',
        },

        {
          label: 'Suggestions',
          value: 'suggestions',
        },

        {
          label: 'Reporting Problems',
          value: 'error',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn', 'hasRole']),
    ...mapState('auth', ['user']),
    ...mapState('site', ['version', 'brand']),
    jobsLoading() {
      return this.$store.state.post.loading;
    },
  },
  methods: {
    /* eslint-disable space-before-function-paren */
    toggleDrawer() {
      if (!this.leftDrawerOpen) {
        this.leftDrawerOpen = true;
        this.miniDrawer = false;
      } else if (this.miniDrawer) {
        this.leftDrawerOpen = false;
      } else {
        this.miniDrawer = true;
      }
    },
    logout() {
      console.log('calling logout');
      this.$store.dispatch(`auth/${AuthActionTypes.AuthLogoutRequest}`).then(() => {
        this.$router.push('/auth/login');
      });
    },
    showContactDialog() {
      if (this.user) {
        this.contact.email = this.user.email;
      } else {
        this.contact.email = null;
      }

      this.contact.page = this.$route.fullPath;
      this.contactDialog = true;
    },
    submitBugReport() {},
    async submitContactForm() {
      console.log('submit called', this.contact);
      this.sendingMessage = true;

      try {
        const apiData = await ApiService.post('/api/contact', this.contact);
        console.log('in post save', apiData);

        Notify.create({
          position: 'top-right',
          color: 'green',
          icon: 'check',
          timeout: 2500,
          message: apiData.data.message,
          actions: [{ icon: 'close', color: 'white' }],
        });

        this.$refs.contactForm.reset();
        this.contact.message = '';

        this.showContactDialog = false;

        return true;
      } catch (err) {
        this.sendingMessage = false;
        console.log('ON TOP', err);
        if (err.response) {
          console.log('caught postSubmit err', err.response);
          this.processErrorData(err.response.data);

          this.$refs.contactForm.validate();
        } else {
          console.log('Error: ', err.message);
          throw err;
        }

        return false;
      } finally {
        this.sendingMessage = false;
      }
    },
    setDebug(val) {
      if (val) {
        this.$store.dispatch('admin/[Admin] Add Role', 'debug');
      } else {
        this.$store.dispatch('admin/[Admin] Remove Role', 'debug');
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name == 'index') {
      this.leftDrawerOpen = false;
    }
    next();
  },
};
</script>

<style></style>
